import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { queryParams } from '../../../utils/helper'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/digital-product/`

export const digitalProductsApiService = createApi({
    reducerPath: 'digitalProductsApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authSlice.token
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getDigitalProducts: builder.query({
            query: (data) => {

                if (data?.id) {
                    return `get/${data?.id}`
                }

                let query = queryParams(data)

                return `get${query}`

            },
            keepUnusedDataFor: 0
        }),
        buyProduct: builder.mutation({
            query: (id) => ({
                url: `buy/${id}`,
                method: 'POST'
            })
        }),
    })
})

export const {
    useGetDigitalProductsQuery,
    useBuyProductMutation
} = digitalProductsApiService