import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { toast } from "react-toastify"
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    PURGE,
    REGISTER,
    REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import AuthSlice, { logout } from "../Slices/Auth"
import GeneralSlice from "../Slices/General"
import { authApiService } from "../Apis/Auth"
import { categoryApiService } from "../Apis/Category"
import { courseApiService } from "../Apis/Course"
import { generalApiService } from "../Apis/General"
import { userApiService } from "../Apis/User"
import { reviewApiService } from "../Apis/Review"
import { packageApiService } from "../Apis/Packages"
import { digitalProductsApiService } from "../Apis/DigitalProducts"
import { guidelineApiService } from "../Apis/Guideline"
import { newsletterApiService } from "../Apis/Newsletter"
import { feedbackApiService } from "../Apis/Feedback"
import { pressApiService } from "../Apis/Press"

const apiErrorHandler = (store) => (next) => (action) => {
    if (action.type.endsWith('/rejected')) {

        toast.error(action?.payload?.data?.message)

        if (action?.payload?.status === 500) {
            store.dispatch(logout())
        }

    }

    return next(action);
};

const persistConfig = {
    key: 'root',
    storage: storage
}

export const rootReducers = combineReducers({
    authSlice: AuthSlice,
    generalSlice: GeneralSlice,
    [authApiService.reducerPath]: authApiService.reducer,
    [categoryApiService.reducerPath]: categoryApiService.reducer,
    [courseApiService.reducerPath]: courseApiService.reducer,
    [generalApiService.reducerPath]: generalApiService.reducer,
    [userApiService.reducerPath]: userApiService.reducer,
    [reviewApiService.reducerPath]: reviewApiService.reducer,
    [packageApiService.reducerPath]: packageApiService.reducer,
    [digitalProductsApiService.reducerPath]: digitalProductsApiService.reducer,
    [guidelineApiService.reducerPath]: guidelineApiService.reducer,
    [newsletterApiService.reducerPath]: newsletterApiService.reducer,
    [feedbackApiService.reducerPath]: feedbackApiService.reducer,
    [pressApiService.reducerPath]: pressApiService.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    })
        .concat(authApiService.middleware)
        .concat(categoryApiService.middleware)
        .concat(courseApiService.middleware)
        .concat(generalApiService.middleware)
        .concat(userApiService.middleware)
        .concat(reviewApiService.middleware)
        .concat(packageApiService.middleware)
        .concat(digitalProductsApiService.middleware)
        .concat(guidelineApiService.middleware)
        .concat(newsletterApiService.middleware)
        .concat(feedbackApiService.middleware)
        .concat(pressApiService.middleware)
        .concat(apiErrorHandler)
})

setupListeners(store.dispatch)