import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/newsletter/`

export const newsletterApiService = createApi({
    reducerPath: 'newsletterApi',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints: (builder) => ({
        createNewsletter: builder.mutation({
            query: (body) => ({
                url: 'create',
                method: "POST",
                body
            })
        })
    })
})

export const {
    useCreateNewsletterMutation
} = newsletterApiService