import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/review/`

export const reviewApiService = createApi({
    reducerPath: 'reviewApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authSlice.token
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        createReview: builder.mutation({
            query: (body) => ({
                url: 'create',
                method: "POST",
                body
            })
        })
    })
})

export const {
    useCreateReviewMutation
} = reviewApiService