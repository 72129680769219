import { createSlice } from "@reduxjs/toolkit"
import { generalApiService } from "../../Apis/General"
import { toast } from "react-toastify"

let initialState = {
    general: null,
    cart: [],
    direct_checkout: null
}

export const GeneralSlice = createSlice({
    name: 'generalSlice',
    initialState,
    reducers: {
        handleCart: (state, action) => {

            if (action?.payload) {

                let data = state?.cart ? [...state.cart] : []

                let index = data.findIndex(item => item?._id === action?.payload?._id)

                if (index > -1) {
                    data.splice(index, 1)
                    toast.error("Item Removed From Cart")
                } else {
                    toast.success("Item Added To Cart")
                    data.push(action?.payload)
                }
                state.cart = data
            }

            return state

        },
        resetCart: (state, action) => {
            state.cart = []
            state.direct_checkout = null
        },
        handleDirectCheckout: (state, action) => {
            if (action?.payload) {
                state.direct_checkout = action?.payload
            }
            return state
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(generalApiService.endpoints.getData.matchFulfilled, (state, action) => {
            let { success, data } = action?.payload
            if (success) {
                state.general = data
            }
        })
    }
})

export const { handleCart, resetCart, handleDirectCheckout } = GeneralSlice.actions
export default GeneralSlice.reducer